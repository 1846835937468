import React from 'react';
import logo from './logo.svg';
import './App.css';
import WelcomePage from './components/WelcomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PolicyPage from './components/PolicyPage';
import ContactPage from './components/ContactPage';
import DeletePage from './components/DeletePage';



const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/policy" element={<PolicyPage/>} />
          <Route path="/support" element={<ContactPage/>} />
          <Route path="/delete" element={<DeletePage/>} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;


