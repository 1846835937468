import { Box } from "@mui/system";
import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <Box className="footer">
      <Box >
        <img src={require("../assests/paw.png")} alt="Petcarians" className="footer-logo"></img>
      </Box>
      <p className="footer-text">
      @ 2024 PetCarians | All Rights Reserved ABN: 74 680 425 656
      </p>
    </Box>
  );
};

export default Footer;