import { Avatar, Button, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useRef } from "react";
// Import Link from react-router-dom
import "./Header.css";
import { useNavigate } from "react-router-dom";

type Props = {
  buttonComp: React.ReactNode;
  hasHiddenAuthButtons: boolean;
  showCardFunction: (isShown: boolean) => void;
};

const Header: React.FC<Props> = ({ hasHiddenAuthButtons, showCardFunction }) => {

  const showCrad = useRef<boolean>(false);

  const handleShowCard = () => {
    showCrad.current = !showCrad.current;
    showCardFunction(showCrad.current);
  }

  const navigate = useNavigate();




  return (
    <Box className="header">

      <Box className="header-title" onClick={() => navigate("/")}>

        <img src={require("../assests/paw.png")} alt="PetCarians"></img>

        <Typography color="white">PetCarians</Typography>

      </Box>

      <Box className="header-buttons">


        <Box onClick={() => navigate("/policy")} style={{ cursor: "pointer" }}>
          <Typography color="white">Policy</Typography>
        </Box>
        <Box onClick={() => navigate("/support")} style={{ cursor: "pointer" }}>
          <Typography color="white">Support</Typography>
        </Box>
        <Box onClick={() => navigate("/delete")} style={{ cursor: "pointer" }}>
          <Typography color="white">Remove Account</Typography>
        </Box>

      </Box>


      {/* <Box className="header-links">
        <Box onClick={() => { }} style={{ cursor: "pointer" }}>
          <Button>Download</Button>
        </Box>
      </Box> */}
    </Box>
  );
};

export default Header;
