import React from 'react';
import './Card.css';  // Assuming you have a CSS file for styling

const Card: React.FC = () => {
  return (
    <div className="card">
    <span className="card__title">Newsletter</span>
    <p className="card__content">
      Get existential crisis delivered straight to your inbox every week.
    </p>
    <form className="card__form">
      <input  type="email" placeholder="Your life" />
      <button className="card__button">Click me</button>
    </form>
  </div>
  );
};

export default Card;