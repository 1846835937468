// WelcomePage.tsx
import React from 'react';
import {  Typography,  Box } from '@mui/material';
import "./WelcomePage.css";
import Header from './Header';
import Footer from './Footer';
import Card from './Card';

const WelcomePage :React.FC =() => {
  const [showCrad, setShowCard] = React.useState<boolean>(false);

  return (
    <Box
    display="flex"
    flexDirection="column"
    justifyContent={"space-between"}
    minHeight="100vh"
  >
      <Header buttonComp={undefined} hasHiddenAuthButtons={true} showCardFunction={setShowCard}/>
      {
        showCrad && (
        <Box className="overlay">
          {[1,2,3,4,5].map((item, index) => (
              <Card key={index}/>
          ))}
        
        
        </Box>
      )}
      <Box className="content">
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to PetCarians!
        </Typography>
        <Typography variant="h3" component="p" gutterBottom>
          We're glad to have you here.
        </Typography>
        <Typography variant="body1" component="h4" gutterBottom>
          Escape the worries of holiday separations with PetCarians, where your furry companions find solace
          in the care of trusted friends. Our platform nurtures a community bond, empowering members to
          support each other in pet care endeavors. Enjoy your vacations unburdened by concern, knowing
          your pets are in familiar hands.
        </Typography>
        <Typography variant="body1" component="h4">
          Bid farewell to the stress of arranging care; with PetCarians, you and your friends embark on
          holiday adventures seamlessly, united by shared affection for your cherished pets.
        </Typography>
      </Box>
      <Footer/>
      </Box>
  )
};

export default WelcomePage;

